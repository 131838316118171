import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HeaderIntranet from './EspaceMembre/HeaderIntranet';
import Agenda from './EspaceMembre/Agenda';
import Documents from './EspaceMembre/Documents';
import Accueil from './EspaceMembre/AcceuilMembre';
import '../styles/EspaceMembre.css';

const EspaceMembre = () => {
    return (
        <div className="espace-membre">
            <HeaderIntranet />
            <div className="intranet-content">
                <Routes>
                    <Route path="accueil" element={<Accueil />}/>
                    <Route path="agenda" element={<Agenda />} />
                    <Route path="documents" element={<Documents />} />
                </Routes>
            </div>
        </div>
    );
};

export default EspaceMembre;
