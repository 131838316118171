import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Page from './components/Page';
import Footer from './components/Footer';
import { pageData } from './datas/pageData';
import Login from './components/Login';
import EspaceMembre from './components/EspaceMembre';
import PrivateRoute from './components/PrivateRoute';
import './App.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); // Vérifie si un token est présent

    return (
        <Router>
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <div className="main-content">
                <Routes>
                    {/* Route pour la page d'accueil */}
                    <Route
                        path="/"
                        element={<Page title={pageData.accueil.title} content={pageData.accueil.content} />}
                    />

                    {/* Génération dynamique des routes principales et sous-pages */}
                    {Object.keys(pageData).map((pageKey) => {
                        const page = pageData[pageKey];

                        return (
                            <React.Fragment key={pageKey}>
                                <Route
                                    path={`/${pageKey}`} // Route principale
                                    element={<Page title={page.title} content={page.content} component={page.component} />}
                                />

                                {/* Gérer les sous-pages si elles existent */}
                                {page.subpages && Object.keys(page.subpages).map((subKey) => {
                                    const subpage = page.subpages[subKey];
                                    return (
                                        <Route
                                            key={subKey}
                                            path={`/${pageKey}/${subKey}`} // Route pour la sous-partie
                                            element={<Page title={subpage.title} content={subpage.content} />}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}

                    {/* Route pour la page de connexion */}
                    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />

                    {/* Route protégée pour l'espace membre */}
                    <Route
                        path="/espace-membre/*"
                        element={
                            <PrivateRoute>
                                <EspaceMembre />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
