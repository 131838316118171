import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar'; // Importer le calendrier
import 'react-calendar/dist/Calendar.css';
import '../../styles/Agenda.css';

const Agenda = () => {
    const [date, setDate] = useState(new Date()); // Date sélectionnée sur le calendrier
    const [events, setEvents] = useState([]); // Liste des événements
    const [eventTitle, setEventTitle] = useState(''); // Titre de l'événement
    const [startTime, setStartTime] = useState(''); // Heure de début
    const [endTime, setEndTime] = useState(''); // Heure de fin
    const [isEditing, setIsEditing] = useState(false); // État d'édition
    const [currentEventIndex, setCurrentEventIndex] = useState(null); // Indice de l'événement en cours de modification

    useEffect(() => {
        const storedEvents = localStorage.getItem('events');
        if (storedEvents) {
            setEvents(JSON.parse(storedEvents));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('events', JSON.stringify(events));
    }, [events]);

    const handleAddEvent = (e) => {
        e.preventDefault();

        if (startTime >= endTime) {
            alert("L'heure de fin doit être après l'heure de début.");
            return;
        }

        if (eventTitle.trim() === '') return;

        if (isEditing) {
            const updatedEvents = events.map((event, index) =>
                index === currentEventIndex
                    ? { ...event, title: eventTitle, date: date.toDateString(), startTime, endTime }
                    : event
            );
            setEvents(updatedEvents);
            setIsEditing(false);
            setCurrentEventIndex(null);
        } else {
            const newEvent = {
                title: eventTitle,
                date: date.toDateString(),
                startTime,
                endTime,
            };
            setEvents([...events, newEvent]);
        }

        setEventTitle('');
        setStartTime('');
        setEndTime('');
    };

    const handleDeleteEvent = (index) => {
        const updatedEvents = events.filter((_, i) => i !== index);
        setEvents(updatedEvents);
    };

    const handleEditEvent = (index) => {
        const eventToEdit = events[index];
        setEventTitle(eventToEdit.title);
        setStartTime(eventToEdit.startTime);
        setEndTime(eventToEdit.endTime);
        setCurrentEventIndex(index);
        setIsEditing(true);
    };

    return (
        <div className="agenda-section">
            <h2>Agenda</h2>

            {/* Section Calendrier */}
            <div className="calendar-container">
                <Calendar onChange={setDate} value={date} />
                <p>Date sélectionnée : {date.toDateString()}</p>
            </div>

            {/* Section Ajouter/Modifier un événement */}
            <div className="add-event-form">
                <h3>{isEditing ? 'Modifier l\'événement' : 'Ajouter un événement'}</h3>
                <form onSubmit={handleAddEvent}>
                    <input
                        type="text"
                        placeholder="Titre de l'événement"
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                        required
                    />
                    <div className="time-inputs">
                        <label>
                            Heure de début :
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Heure de fin :
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <button type="submit">{isEditing ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
            </div>

            {/* Section Liste des événements */}
            <div className="events-list">
                <h3>Événements</h3>
                {events.length === 0 ? (
                    <p>Aucun événement pour le moment.</p>
                ) : (
                    <ul>
                        {events
                            .filter((event) => event.date === date.toDateString())
                            .map((event, index) => (
                                <li key={index}>
                                    <span>{event.title}</span> - <em>{event.date}</em> de {event.startTime} à {event.endTime}
                                    <div className="event-actions">
                                        <button onClick={() => handleEditEvent(index)}>Modifier</button>
                                        <button onClick={() => handleDeleteEvent(index)}>Supprimer</button>
                                    </div>
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Agenda;
