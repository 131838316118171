import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SignUp.css';

const SignUp = () => {
    // États pour les informations d'inscription
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // État pour vérifier si l'utilisateur est connecté

    // États pour les checkboxes
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);

    // Utiliser useEffect pour vérifier si l'utilisateur est connecté au montage du composant
    useEffect(() => {
        const token = localStorage.getItem('token'); // Récupérer le token JWT
        if (token) {
            setIsLoggedIn(true); // Si un token est trouvé, l'utilisateur est connecté
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Vérifier que les deux checkboxes sont cochées
        if (!isChecked1 || !isChecked2) {
            setMessage('Vous devez accepter les deux options avant de vous inscrire.');
            return;
        }

        const userData = {
            username,
            email,
            password
        };

        // Envoyer les données vers le backend avec Axios
        axios.post('http://localhost:4000/signup', userData)
            .then((response) => {
                setMessage('Inscription réussie !');
            })
            .catch((error) => {
                setMessage('Erreur lors de l\'inscription');
                console.error(error);
            });
    };

    return (
        <div>
            {/* Afficher un contenu différent si l'utilisateur est connecté */}
            {isLoggedIn ? (
                <div>
                    <h2>Vous êtes déjà connecté</h2>
                    <p>Vous ne pouvez pas vous inscrire à nouveau. Rendez-vous sur l'espace membre.</p>
                </div>
            ) : (
                <div>
                    <h2>Inscription</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Nom d'utilisateur</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Mot de passe</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        {/* Ajout des checkboxes */}
                        <div className="checkbox-container">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isChecked1}
                                    onChange={(e) => setIsChecked1(e.target.checked)}
                                />
                                J'accepte les termes et conditions
                            </label>
                            <br />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isChecked2}
                                    onChange={(e) => setIsChecked2(e.target.checked)}
                                />
                                Je souhaite recevoir des notifications par email
                            </label>
                        </div>

                        <button type="submit">S'inscrire</button>
                    </form>
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
};

export default SignUp;
