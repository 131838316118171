import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Médiationsanté - Tous droits réservés</p>
            <p>
                <a href="/mentions-legales">Mentions légales</a> |
                <a href="/politique-confidentialite">Politique de confidentialité</a>
            </p>
        </footer>
    );
};

export default Footer;
