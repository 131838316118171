import SignUp from "../components/SignUp";

export const pageData = {
    'accueil': {
        title: 'Accueil',
        content: 'Bienvenue sur notre site. Ceci est la page d\'accueil. Veuillez choisir une option dans le menu pour en savoir plus sur nous.',
        component: 'null'
    },
    'qui-sommes-nous': {
        title: 'Qui sommes-nous ?',
        content: '',
        subpages: {
            'objectifs': {
                title: 'Objectifs',
                content: 'Nos objectifs sont de...'
            },
            'organisation': {
                title: 'Notre organisation',
                content: 'Nous sommes organisés comme suit...'
            },
            'contact': {
                title: 'Contactez-nous',
                content: 'Pour nous contacter, veuillez...'
            }
        }
    },
    'mediation-en-sante': {
        title: 'Médiation en santé',
        content: '',
        subpages: {
            'definition': {
                title: 'Qu\'est-ce que la médiation en santé ?',
                content: 'Définition et cadre d\'intervention de la médiation en santé...'
            },
            'metier-mediateur': {
                title: 'Médiateur en santé, un métier',
                content: 'Voici en quoi consiste le métier de médiateur en santé...'
            },
            'formations': {
                title: 'Formations',
                content: 'Informations sur les formations en médiation...'
            },
            'temoignages': {
                title: 'Témoignages',
                content: 'Voici quelques témoignages de médiateurs en santé...'
            },
            'ressources': {
                title: 'Documentation ou Ressources',
                content: 'Ressources et documentation sur la médiation en santé...'
            }
        }
    },
    'nous-rejoindre': {
        title: 'Nous rejoindre',
        content: 'Voici l\'espace pour nous rejoindre.',
        component: SignUp
    }
};
