import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/HeaderIntranet.css"

const HeaderIntranet = () => {
    return (
        <header className="header-intranet">
            <nav>
                <ul className="intranet-menu">
                    <li className="intranet-menu-item">
                        <Link to="/espace-membre/accueil">Accueil</Link>
                    </li>
                    <li className="intranet-menu-item">
                        <Link to="/espace-membre/agenda">Agenda</Link>
                    </li>
                    <li className="intranet-menu-item">
                        <Link to="/espace-membre/documents">Déposer des Documents</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default HeaderIntranet;
