import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { pageData } from '../datas/pageData';
import '../styles/Header.css';

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Utiliser useLocation pour vérifier le chemin actuel

    // Fonction pour gérer la déconnexion
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token && location.pathname !== '/login') {
            handleLogout();
        }
    }, [location, navigate, setIsLoggedIn]);

    return (
        <header className="header">
            <nav>
                <ul className="menu">
                    {Object.keys(pageData).map((pageKey) => {
                        const page = pageData[pageKey];
                        return (
                            <li key={pageKey} className="menu-item">
                                <Link to={`/${pageKey}`}>{page.title}</Link>

                                {page.subpages && (
                                    <ul className="submenu">
                                        {Object.keys(page.subpages).map((subKey) => {
                                            const subpage = page.subpages[subKey];
                                            return (
                                                <li key={subKey} className="submenu-item">
                                                    <Link to={`/${pageKey}/${subKey}`}>{subpage.title}</Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                    <li className="menu-item">
                        <Link to="/espace-membre">Espace Membre</Link>
                    </li>
                    <li className="menu-item login-button">
                        {isLoggedIn ? (
                            <button onClick={handleLogout}>Logout</button>
                        ) : (
                            <button onClick={() => navigate('/login')}>Login</button>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
