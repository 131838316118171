import React from 'react';

const Page = ({ title, content, subpages, component: Component }) => {
    return (
        <div className="page">
            <h1>{title}</h1>
            <p>{content}</p>

            {/* Rendre le composant spécifique si défini */}
            {Component && <Component />}

            {/* Affichage des sous-pages si elles existent */}
            {subpages && (
                <div className="subpages">
                    <h2>Subpages</h2>
                    <ul>
                        {Object.keys(subpages).map((subKey) => {
                            const subpage = subpages[subKey];
                            return (
                                <li key={subKey}>
                                    <h3>{subpage.title}</h3>
                                    <p>{subpage.content}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Page;
