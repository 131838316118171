import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Importer Axios pour gérer les requêtes HTTP
import '../styles/Login.css'; // Importer le CSS

function Login({ setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();


    const handleLogin = (e) => {
        e.preventDefault();

        const userData = {
            email: username,
            password
        };

        axios.post('http://localhost:4000/login', userData)
            .then((response) => {
                const token = response.data.token; // Récupérer le token JWT depuis la réponse
                localStorage.setItem('token', token); // Stocker le token dans le localStorage
                setIsLoggedIn(true); // Mettre à jour l'état de connexion
                navigate('/espace-membre/accueil');
            })
            .catch((error) => {
                setError('Identifiants incorrects');
                console.error(error);
            });
    };

    return (
        <div className="login-page">
            <h2>Connexion à l'espace membre</h2>
            <div className="login-form-container">
                <form onSubmit={handleLogin}>
                    <div>
                        <label>Email</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Mot de passe</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Se connecter</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
