import React from 'react';
import '../../styles/AccueilMembre.css';
import { Link } from 'react-router-dom';

const AccueilMembre = () => {
    return (
        <div className="accueil-membre">
            {/* Header de la page d'accueil de l'espace membre */}
            <header className="membre-header">
                <h1>Bienvenue dans votre Espace Membre</h1>
                <p>Accédez à toutes vos fonctionnalités et ressources en un seul endroit.</p>
            </header>

            {/* Section de Navigation des fonctionnalités */}
            <section className="features-section">
                <div className="features">
                    <div className="feature">
                        <Link to="/espace-membre/agenda">
                            <h2>Agenda</h2>
                            <p>Consultez et gérez vos rendez-vous et événements importants.</p>
                        </Link>
                    </div>
                    <div className="feature">
                        <Link to="/espace-membre/documents">
                            <h2>Dépôt de Documents</h2>
                            <p>Déposez et consultez vos documents partagés de manière sécurisée.</p>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AccueilMembre;
